import React from "react"
import { Link } from "gatsby"
import { ModalRoutingContext } from "gatsby-plugin-modal-routing"

import opcoes from "../images/go-burguer-opcoes-paes.jpg"

const closeStyle = {
  background: "black",
  color: "white",
  textDecoration: "none",
  padding: "6px 10px",
  borderRadius: "50%",
  position: "absolute",
  right: "-15px",
  top: "-10px",
  boxShadow: "0 0 5px",
}

const ModalExamplePage = () => (
  <ModalRoutingContext>
    {({ modal, closeTo }) => (
      <div>
        <Link style={closeStyle} to="/#monte">
          x
        </Link>
        <img
          style={{ width: "100%", maxWidth: "380px" }}
          src={opcoes}
          alt="Escolha seu pão!"
        />
      </div>
    )}
  </ModalRoutingContext>
)

export default ModalExamplePage
